<script setup lang="ts">
import { usePageField } from '@voix/composables/usePageField'
import { usePageStore } from '@voix/store/pageStore'

defineLayout({
  label: 'The Cove',

  colors: {
    fields: {
      picker: true,
      palette: true,
    },
    background: {
      picker: true,
      palette: true,
    },
    foreground: {
      picker: true,
      palette: true,
    },
  },
  colorPalette: {
    '#be914f': {
      fields: true,
      background: true,
      foreground: true,
    },
    '#ebe3dd': {
      fields: true,
      background: true,
      foreground: true,
    },
    '#282829': {
      fields: true,
      background: true,
      foreground: true,
    },
    '#000000': {
      fields: true,
      background: true,
      foreground: true,
    },
  },

  grid: {
    default: {
      container: false,
      columns: 6,
      gutter: '3rem',
    },
    lg: {
      container: true,
      columns: 12,
      gap: '4rem', // .gap-8
      gutter: '2rem',
    },
  },
})

useAtlantisHead()

// Generated much of this at https://realfavicongenerator.net
useHead({
  htmlAttrs: {
    lang: 'en-US',
  },
  meta: [
    { name: 'msapplication-TileColor', content: '#000000' },
    { name: 'msapplication-config', content: '/favicons/atlantisbahamas/browserconfig.xml' },
    { name: 'theme-color', content: '#000000' },
  ],
  link: [
    { rel: 'apple-touch-icon', sizes: '180x180', href: '/favicons/atlantisbahamas/apple-touch-icon.png' },
    { rel: 'icon', type: 'image/png', sizes: '32x32', href: '/favicons/atlantisbahamas/favicon-32x32.png' },
    { rel: 'icon', type: 'image/png', sizes: '16x16', href: '/favicons/atlantisbahamas/favicon-16x16.png' },
    { rel: 'manifest', href: '/favicons/atlantisbahamas/site.webmanifest' },
    { rel: 'mask-icon', href: '/favicons/atlantisbahamas/safari-pinned-tab.svg', color: '#be914f' },
    { rel: 'shortcut icon', href: '/favicons/atlantisbahamas/favicon.ico' },
  ],
  script: [
    { src: '//fast.wistia.com/assets/external/E-v1.js', async: true },
  ],
})

const route = useRoute()
const pageStore = usePageStore()
const isVoixTemplatizer = inject('isVoixTemplatizer', false)
const config = useRuntimeConfig()

const pageField = usePageField()
const hidePopup = pageField.get('hidePopup', false)
const hideNavigation = pageField.get('hideNavigation', false)
const hideFooter = pageField.get('hideFooter', false)

const siteId = pageStore.currentPage?.site_id || 1

const { data: mainMenu }: { data: Record<string, any> } = await useFetch(
  `${config.public.voixApiBaseUrl}/api/sites/${siteId}/menus/Glue%20Main%20Navigation`,
)
const { data: footerMenu }: { data: Record<string, any> } = await useFetch(
  `${config.public.voixApiBaseUrl}/api/sites/${siteId}/menus/Footer%20Navigation`,
)
const { data: legalMenu }: { data: Record<string, any> } = await useFetch(
  `${config.public.voixApiBaseUrl}/api/sites/${siteId}/menus/Legal%20Footer%20Links`,
)

const nochrome = route.query.nochrome

onMounted(() => {
  // Error Logs ERROR_MESSAGE or ERROR_MESSAGENO_ROOMS_FOUND
  if (process.client) {
    try {
      const params = new URLSearchParams(window.location.search)
      if (params.has('ERROR_MESSAGE') || params.has('ERROR_MESSAGENO_ROOMS_FOUND')) {
        const bookingSubmittionCookie = useCookie('booking-submission-id')
        const bookingSubmittionFrom = useCookie('booking-submitted-from')

        if (bookingSubmittionCookie.value)
          $voixFetch(`/user-booking-redirected?submission_id=${bookingSubmittionCookie.value}&from=${bookingSubmittionFrom.value}`)

        bookingSubmittionCookie.value = null
        bookingSubmittionFrom.value = null
      }
    }
    catch (e) {
      console.log('error', e)
    }
  }
})
</script>

<template>
  <div
    id="app" class="relative cove-theme"
    :class="{
      'min-h-screen': !isVoixTemplatizer,
    }"
  >
    <div id="glue" class="text-cove-dark bg-cove-light" :class="{ nochrome }">
      <div class="relative z-40">
        <div id="portal-modal" />
      </div>

      <div class="flex justify-center">
        <div class="relative z-10 w-full">
          <div v-if="(!nochrome && pageStore.currentPage?.site_id !== 4) && !hideNavigation.value && !isVoixTemplatizer">
            <!-- TODO: Missing ta_track_num -->
            <TheCoveDesktopNavigation class="hidden xl:block" :menu="mainMenu" />
            <TheCoveMobileNavigation class="xl:hidden" :menu="mainMenu" />
          </div>

          <div
            class="relative "
            :class="{
              'min-h-[50vh]': !isVoixTemplatizer,
            }"
          >
            <slot />
          </div>

          <div v-if="!nochrome && !isVoixTemplatizer" class="relative z-0">
            <TheCoveFooter v-if="footerMenu?.data && legalMenu?.data && !hideFooter.value" :menu="footerMenu.data" :legal-menu="legalMenu.data" />
            <AtlantisbahamasAlert />
          </div>
        </div>
      </div>
      <GlobalUIHelpBot v-if="!isVoixTemplatizer" />
      <ClientOnly>
        <GlobalUIPopupForm
          v-if="!hidePopup.value"
          form="popup-general-newsletter"
          image="/imgs/newsletter-signup-form/atlantisbahamas.jpg"
          title="SIGN UP FOR SPECIAL OFFERS AND MORE"
          terms="You agree to receive marketing emails from us. You can opt out at any time"
          travel-agent="Are you a travel agent?"
        />
      </ClientOnly>
    </div>
  </div>
</template>

<style lang="scss">
@use '~/assets/sass/cove.scss';
</style>
